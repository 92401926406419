/* Container for the whole page */
.welcome-container {
    display: flex;
    flex-direction: column;
    min-height: 150vh;
}

/* Flex container for content */
.welcome-content {
    display: flex;
    flex: 1; /* Take up remaining space */
    justify-content: space-between;
    align-items: stretch; /* Make children fill available height */
    padding: 20px; /* Optional padding */
    flex-direction: row; /* Make children align in a row */
    margin-bottom: 100px;
}

/* Styling for iframe */
.youtube-video, .profile-form {
    flex: 1; /* Share available space equally */
    margin: 10px; /* Optional margin for spacing */
}

/* Ensure iframe fills its container */
.youtube-video iframe {
    width: 100%;
    height: 100%;
    border: none; /* Remove border */
}

/* Ensure ProfileForm fills its container */
.profile-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .welcome-content {
        flex-direction: column; /* Stack children vertically on smaller screens */
        padding: 10px; /* Adjust padding */
    }

    .youtube-video, .profile-form {
        margin: 5px 0; /* Reduce margin */
    }
}
