.footer {
  
}
.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    margin-top: 30px;
}

.footer-stack {
    display: flex;
    justify-content: space-between; /* This will push the button to the flex end */
    align-items: center; /* Center the items vertically */
}

.footer-text {
    margin: 0; /* Remove default margin */
    text-align: center; /* Center text */
    flex-grow: 1; /* Allow the text to take up remaining space */
}

.footer-button {
    margin-left: auto; /* Push the button to the end */
}
