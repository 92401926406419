.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.header img {
  max-height: 100px;
  max-width: 100%;
  align-self: center  ;
}

@media (max-width: 768px) {
  .header {
      flex-direction: column;
      height: auto;
      padding: 20px;
  }

  .header img {
      margin: 10px 0;
  }
}
