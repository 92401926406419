.user-card {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.user-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-img {
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #f8f9fa;
}

.card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.edit-btn, .delete-btn {
    flex: 1;
    margin: 0 5px;
}

.edit-btn {
    background-color: #007bff;
    border: none;
}

.delete-btn {
    background-color: #dc3545;
    border: none;
}

.edit-btn:hover, .delete-btn:hover {
    opacity: 0.8;
}
