/* Users.css */

/* Adjust the size of the search bar */
.search-bar {
    max-width: 300px; /* Adjust this value to set the desired width */
    margin: 0 auto; /* Center align the search bar */
}

.user-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Add space between cards */
}

.user-card {
    flex: 1 1 calc(25% - 16px); /* Four cards per row with space in between */
    max-width: calc(25% - 16px); /* Ensure cards do not exceed the row width */
    box-sizing: border-box;
}

/* Additional responsive design for smaller screens */
@media (max-width: 1200px) {
    .user-card {
        flex: 1 1 calc(33.33% - 16px); /* Three cards per row on medium screens */
        max-width: calc(33.33% - 16px);
    }
}

@media (max-width: 768px) {
    .user-card {
        flex: 1 1 calc(50% - 16px); /* Two cards per row on smaller screens */
        max-width: calc(50% - 16px);
    }
}

@media (max-width: 576px) {
    .user-card {
        flex: 1 1 100%; /* One card per row on very small screens */
        max-width: 100%;
    }
}
