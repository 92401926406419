.profile-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.form-group {
    margin-bottom: 15px;
    width: 100%;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="file"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.image-placeholder {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    
}

.image-placeholder-text {
    color: #ccc;
    font-size: 14px;
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.form-group button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.form-group button:hover {
    background-color: #45a049;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
